.textContent{
    text-align: left;

}
@media screen and (max-width: 768px) {
    .textContent{
        font-size: 16px ;
        padding: 1rem ;
    }
}
@media screen and (min-width: 769px){
    .textContent{
        font-size: 16px ;
        padding: 3rem ;

    }
}