
.index{
    padding: 0;
    margin: 0;
    background-color: #282c34;
}

.Introduction{
    height: 43rem;
    overflow:auto;
    background-color: white;
    padding-left: 3rem;
}

