iframe{
    height: 100%;
    width: 100%;
}
.ifr{
    height: 100%;
}
.text{
    padding: 2rem;
}
.Paragraph {
    text-align: left;
}